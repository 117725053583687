<template>
  <div class="main-body">
    <layout-header />
    <div class="container main-container main-content">
      <nuxt />
    </div>
    <div class="section-info">
      <div class="container">
        <div class="section-payments">
          <div>
            <b>{{ $t('project.layout.footer.sectionInfo.payments.title') }}</b>
            <p>{{ $t('project.layout.footer.sectionInfo.payments.subtitle') }}</p>
          </div>
          <payments-icons />
        </div>
      </div>
    </div>
    <footer-schuette v-if="project === 'schuette'" />
    <footer-rzaska v-if="project === 'rzaska'" />
    <global-layout new-cookie />
  </div>
</template>

<script>
import PaymentsIcons from 'marketplace-front-core/components/shared/PaymentsIcons'
import GlobalLayout from 'marketplace-front-core/components/layout/GlobalLayout'
import LayoutHeader from '~/components/layout/Header'
import FooterSchuette from '~/components/layout/FooterSchuette'
import FooterRzaska from '~/components/layout/FooterRzaska'

export default {
  components: {
    LayoutHeader,
    FooterSchuette,
    FooterRzaska,
    GlobalLayout,
    PaymentsIcons
  }
}
</script>
